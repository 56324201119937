import React, { useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Button, Form, Input, Tooltip } from 'antd'
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { ChatBranded } from '../../../types/public.type'

const ChatSettingsTabContent: React.FC = () => {
  const [form] = Form.useForm()
  const [logoUrl, setLogoUrl] = useState<File | null>(null)

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setLogoUrl(acceptedFiles[0])
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': [], // Accept only image files
    },
  })

  const createBrandedChat = (body: ChatBranded) => {
    const formData = new FormData()
    formData.append('logoUrl', body.logoUrl, body.logoUrl.name)
    formData.append('ctaLabel', body.ctaLabel)
    formData.append('ctaUrl', body.ctaUrl)
    console.log(formData)
  }

  return (
    <div className="settings-tab">
      <h2>Chat Page Settings</h2>
      <Form form={form} layout="vertical" onFinish={(values) => createBrandedChat({ ...values, logoUrl })}>
        <Form.Item
          label={
            <>
              <span>
                Upload logo
                <Tooltip title="You can add a custom logo to your chat page.">
                  <InfoCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
              </span>
            </>
          }
          className="upload-logo-container"
        >
          <div {...getRootProps()} className="cta-logo">
            {logoUrl ? (
              <div className="cta-logo-image-box">
                <img src={URL.createObjectURL(logoUrl)} alt="logo" className="cta-logo-image" />
              </div>
            ) : (
              <PlusOutlined style={{ fontSize: '45px', color: '#d9d9d9' }} />
            )}
            <input hidden {...getInputProps()} />
          </div>
          <div className="cta-logo-image-actions">
            <Button>Edit</Button>
            <Button>Delete</Button>
          </div>
        </Form.Item>

        <Form.Item
          label={
            <span>
              CTA Title
              <Tooltip title="Add CTA button on top of your chat page">
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </span>
          }
          name="ctaLabel"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={
            <span>
              CTA Url
              <Tooltip title="Url where user will be redirected after clicking the button">
                <InfoCircleOutlined style={{ marginLeft: '5px' }} />
              </Tooltip>
            </span>
          }
          name="ctaUrl"
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Save Settings
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default ChatSettingsTabContent
