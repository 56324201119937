import React, {memo } from 'react'
import './aiHelperHeader.less'

interface Props {
  title: string;
  paragraph?: string;
}

const defaultParagraph =
  `In this section you can review conversations and use them to adjust your Al Assistant. You can also directly contact your customers via conversations’ interface if you have the “Chat in ioni” Call to Action chosen in AI Agent section.`

const AiHelperHeader = ({ title, paragraph = defaultParagraph }: Props) => {
  return (
    <div className="ai-helper-header">
      <h1>{title}</h1>
      {paragraph && <p>{paragraph}</p>}
    </div>
  )
}

export default memo(AiHelperHeader)
