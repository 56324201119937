import React, {memo} from 'react'

interface IProps {
  userName: string
}

const CardHeader = ({userName}: IProps) => {
  return (
    <div className="poll-title">
      <h2 className="poll-title-label">
        Let&apos;s get to know you, <span className="poll-title-label-username">{userName}!</span>
      </h2>
      <p className="poll-text">
        We are eager to learn more about you to help you have the best experience with ioni.
      </p>
    </div>
  )
}

export default memo(CardHeader)
