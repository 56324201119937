import React from 'react'
import { useAppSelector } from '../../../../hooks/appHook'
import Icon from '../../../../components/UI/Icons/Icons'
import LinkCard from './components/LinkCard'

const Intro = () => {
  const { account } = useAppSelector((state) => state.account)
  const { currentStorageId } = useAppSelector((state) => state.storage)

  const buttonsItems = [
    {
      redirectUrl: 'storage',
      title: 'Knowledge Base',
      icon: <Icon name="addData" width={32} height={30} />,
      description: 'Add, search and edit the data in your Knowledge Base. Upload it by individual.',
      children: (
        <>
          <span style={{ fontWeight: '700' }}>URL</span>, <span style={{ fontWeight: '700' }}>HTML</span> or{' '}
          <span style={{ fontWeight: '700' }}>text</span> options.
        </>
      ),
    },
    {
      redirectUrl: 'agent',
      title: 'AI Agent',
      icon: <Icon name="createAssistent" width={28} height={27} />,
      description: 'Create and customize an AI Agent according to your business brand style.',
    },
    {
      redirectUrl: 'conversations',
      title: 'Conversations',
      icon: <Icon name="conversations" width={29} height={24} />,
      description: `Analyze the replies of your chatbot to the customers. Reply manually when needed and ioni will self-learn
      on the updated information.`,
    },
    {
      redirectUrl: `/c/${account.id}?${currentStorageId && `storageId=${currentStorageId}`}`,
      title: 'Test & share chat',
      icon: <Icon name="share" />,
      description: `Test how ioni answers the questions based on the information you added to database.`,
    },
  ]

  return (
    <div id="buttons-container">
      <div className="buttons-group">
        {buttonsItems.map((item) => (
          <LinkCard
            key={item.title}
            redirectUrl={item.redirectUrl}
            title={item.title}
            icon={item.icon}
            description={item.description}
          >
            {item.children}
          </LinkCard>
        ))}
      </div>
    </div>
  )
}

export default Intro
