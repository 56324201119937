import React from 'react'
import Logo from '../UI/Logos/Logo'
export default function SingInInfoBox() {
  return (
    <div className="info-box">
      <h1>AI Agents Platform</h1>
      <p>
        Start Building AI Solutions <br /> That Drive Business REsults
      </p>
      <div className="info-badge-box">
        <a
          href="https://www.producthunt.com/posts/ioni?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-ioni"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=408486&theme=light&period=daily"
            alt="ioni - Boost your customer support productivity with ChatGPT-4 | Product Hunt"
            style={{ width: '250px', height: '54px' }}
            width="250"
            height="54"
          />
        </a>
        <a
          href="https://www.producthunt.com/posts/ioni?embed=true&utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-ioni"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=408486&theme=light&period=weekly"
            alt="ioni - Boost your customer support productivity with ChatGPT-4 | Product Hunt"
            style={{ width: '250px', height: '54px' }}
            width="250"
            height="54"
          />
        </a>
      </div>
      <div>
        <p>
          <a href="https://staging.riece.ai/" target="_blank" rel="noreferrer" className="springs">
            Powered by Riece
            <Logo name="Riece" style={{ display: 'flex', alignItems: 'center' }} />
          </a>
        </p>
      </div>
    </div>
  )
}
